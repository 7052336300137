import React from "react";
import Link from "gatsby-link";
import Background from "@app-components/utils/background";
import Layout from "@app-components/layout/";

const NotFoundPage = () => (
  <Layout getStartedButtons={["demo", "get-started"]} page="404">
    <Background
      tagName="section"
      className="bg-cover bg-no-repeat bg-left center white"
      type="blue-single"
    >
      <div
        className="flex items-center justify-center"
        style={{ height: "100vh" }}
      >
        <div>
          <h1>404 Error!</h1>
          <p>Whoops, this page was not found!</p>
          <Link className="mt2 inline-block" to="/">
            <button className="white">Return Home</button>
          </Link>
        </div>
      </div>
    </Background>
  </Layout>
);

export default NotFoundPage;
